<template>
  <div>
    <el-form-item label="选择商品">
      <el-select
        v-model="formModel.shop.shop_id"
        placeholder="请选择"
      >
        <el-option
          v-for="item in formModel.shops"
          :key="item.id"
          :label="item.product_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="有效范围">
      <el-date-picker
      v-model="formModel.time"
      type="daterange"
      value-format="yyyy-MM-dd"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
    </el-form-item>

    <el-form-item label='内容数据'>
      <div class="b-card">
        <!-- 新增 -->
        <div
          style="margin-bottom:20px;"
          v-for="(item, index) in formModel.shop.s_body"
          :label="'域名' + index"
          :key="item.key"
        >
          <div
            class="b-card"
            style="width:80%;"
          >
            <el-input
              style="margin-bottom:20px;"
              v-model="item.title"
              placeholder='请输入标题'
            ></el-input>
            <el-input
              style="margin-bottom:20px;"
              type="textarea"
              v-model="item.body"
              placeholder='请输入内容...'
            ></el-input>
            <el-button @click.prevent="removeBody(item)">删除</el-button>
          </div>
        </div>

        <!-- 按钮 -->

        <el-button @click="addBody">添加</el-button>
      </div>
    </el-form-item>

    <!-- 封面图 -->
    <el-form-item
      label='详情图'
      :rules="{
            required: true, message: '详情图不能为空', trigger: 'blur'
        }"
    >
      <uploader
        :avatar="formModel.shop.s_cover"
        @on-upload-success="handleUploadSuccess"
      ></uploader>

    </el-form-item>
    <!-- 库存 -->
    <el-form-item
      label='库存'
      :rules="{
            required: true, message: '库存不能为空', trigger: 'blur'
        }"
    >
      <el-input
        style="width:200px;"
        v-model="formModel.shop.s_stock"
        placeholder=""
      ></el-input>

    </el-form-item>
  </div>
</template>

<script>
import Uploader from '@admin/components/uploaders/Uploader'

export default {
  name: 'ShopShop',
  props: {
    formModel: {
      type: Object
    }
  },
  components: { Uploader },
  data () {
    return {}
  },
  methods: {
    handleUploadSuccess (url) {
      this.formModel.shop.s_cover = url
    },
    removeBody (item) {
      var index = this.formModel.shop.s_body.indexOf(item)
      if (index !== -1) {
        this.formModel.shop.s_body.splice(index, 1)
      }
    },
    addBody () {
      this.formModel.shop.s_body.push({
        title: '',
        body: ''
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
