<template>
  <div>

     <el-form-item label="有效范围">
      <el-date-picker
      v-model="formModel.time"
      type="daterange"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期">
    </el-date-picker>
    </el-form-item>

    <el-form-item
      label='内容数据'
      :rules="{
            required: true, message: '兑换码不能为空', trigger: 'blur'
    }"
    >
      <div class="b-card">

        <!-- 新增 -->
        <div
          style="margin-bottom:20px;"
          v-for="(item, index) in formModel.code.i_body"
          :label="'域名' + index"
          :key="item.key"
        >
          <div
            class="b-card"
            style="width:80%;"
          >
            <el-input
              style="margin-bottom:20px;"
              v-model="item.title"
              placeholder='请输入标题'
            ></el-input>
            <el-input
              style="margin-bottom:20px;"
              type="textarea"
              v-model="item.body"
              placeholder='请输入内容...'
            ></el-input>
            <el-button @click.prevent="removeBody(item)">删除</el-button>
          </div>
        </div>

        <!-- 按钮 -->

        <el-button @click="addBody">添加</el-button>
      </div>
    </el-form-item>

   <el-form-item label="详情图" prop="displayIcon">
      <uploader :avatar="formModel.code.i_cover"
                           @on-upload-success="handleUploadSuccess"></uploader>
    </el-form-item>

    <!-- 库存 -->
    <el-form-item
      label='库存'
      :rules="{
            required: true, message: '库存不能为空', trigger: 'blur'
        }"
    >
      <el-input
        style="width:200px;"
        v-model="formModel.code.i_stock"
        placeholder=""
      ></el-input>

    </el-form-item>

    <!-- 兑换码 -->
    <el-form-item
      label='兑换码'
      :rules="{
            required: true, message: '兑换码不能为空', trigger: 'blur'
      }"
    >
      <el-upload
        class="upload-execl"
        :action="uploadUrl"
        accept='.xls,.xlsx'
        multiple
        :limit="3"
        :on-change="upload"
      >
        <el-button
          size="small"
          type="primary"
        >点击上传</el-button>
      </el-upload>

    </el-form-item>
    <!-- 显示数组 -->
    <el-form-item v-if="formModel.code.code_maps.length>1">
      <div class="b-card">
        <div style="height:300px;overflow-y: auto;">
          <span v-for="(item,index) in formModel.code.code_maps" :key="index">
            {{item}}
          </span>
        </div>
        <el-button
          v-if="formModel.code.code_maps.length>1"
          @click="delArr"
          type="danger"
          icon="el-icon-delete"
          circle
        ></el-button>

      </div>
    </el-form-item>
  </div>
</template>

<script>
import Uploader from '@admin/components/uploaders/Uploader'
import XLSX from 'xlsx'
import Auth from '@admin/utils/auth'
export default {
  name: 'ShopCode',
  components: { Uploader },
  props: {
    formModel: {
      type: Object
    }
  },
  async mounted () {
    this.uploadUrl = Auth.getHttpApiUrl() + 'upload/file'
  },
  data () {
    return {
      uploadUrl: ''
    }
  },
  methods: {
    handleUploadSuccess (url) {
      this.formModel.code.i_cover = url
    },
    removeBody (item) {
      var index = this.formModel.code.i_body.indexOf(item)
      if (index !== -1) {
        this.formModel.code.i_body.splice(index, 1)
      }
    },
    addBody () {
      this.formModel.code.i_body.push({
        title: '',
        body: ''
      })
    },
    delArr () {
      this.formModel.code.code_maps = []
    },
    upload (file, fileList) {
      // console.log('file', file)
      // console.log('fileList', fileList)
      let files = { 0: file.raw }
      this.readExcel1(files)
      return true
    },
    readExcel1 (files) {
      // 表格导入
      var that = this
      if (files.length <= 0) {
        // 如果没有文件名
        return false
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$Message.error('上传格式不正确，请上传xls或者xlsx格式')
        return false
      }

      const fileReader = new FileReader()
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0] // 取第一张表
          const ws = XLSX.utils.sheet_to_json(
            workbook.Sheets[wsname], { header: 'A' }
          ) // 生成json表格内容

          that.formModel.code.code_maps = ws
          // 重写数据
          try {
          } catch (err) {
            console.log(err)
          }

          this.$refs.upload.value = ''
        } catch (e) {
          return false
        }
      }
      fileReader.readAsBinaryString(files[0])
    }
  }
}
</script>
<style lang='' scoped>
</style>
