<template>
  <div>
    <!-- 适用等级 -->
    <el-form-item label='适用等级'>
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group
        v-model="formModel.coupon.level_ids"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox
          v-for="item in formModel.level"
          :label="item.id"
          :key="item.id"
          :value="item.id"
        >{{item.name}}</el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <!-- 选择优惠劵id -->
    <el-form-item label="选择优惠券">
       <el-select v-model="formModel.coupon.coupon_id" placeholder="请选择">
          <el-option
            v-for="item in formModel.coupons"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
    </el-form-item>

    <!-- 生效日期  有效时间 -->
    <el-form-item label="生效日期">
      <el-date-picker
        v-model="formModel.coupon.start_time"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
      >
      </el-date-picker>
    </el-form-item>

    <el-form-item label="使用有效期">
      <el-col :span="9">
        <el-input
          type="number"
          :min="1"
          v-model="formModel.coupon.days"
          placeholder="单位天数"
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="发放数量">
      <el-col :span="9">
        <el-input
          type="number"
          :min="1"
          v-model="formModel.coupon.stock"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item label="每人限制领取">
      <el-col :span="9">
        <el-input
          type="number"
          :min="0"
          v-model="formModel.coupon.user_limit"
          placeholder=""
        ></el-input>
      </el-col>
    </el-form-item>

  </div>
</template>

<script>
export default {
  name: 'ShopCoupon',
  props: {
    formModel: {
      type: Object
    }
  },

  data () {
    return {
      checkAll: false,
      isIndeterminate: true
    }
  },
  methods: {
    handleCheckAllChange (val) {
      let arr = []
      if (val) {
        this.formModel.level.forEach(element => {
          arr.push(element.id)
        })
      }
      this.formModel.coupon.level_ids = val ? arr : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      let checkedCount = value.length
      this.checkAll = (checkedCount === this.formModel.level.length)
      this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.formModel.level.length
    }
  }
}
</script>
<style lang='' scoped>
</style>
