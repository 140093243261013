import Vue from 'vue'

const IntegralService = {
  delete (id) {
    return Vue.http.delete('integral/delete', id)
  },

  rule (model = null) {
    const endpoint = 'integral/rule'
    if (model === null) {
      return Vue.http.get(endpoint)
    }
    return Vue.http.post(endpoint, model)
  },
  shops (param) {
    return Vue.http.get('integral/shop', param)
  },

  createShop (param = null) {
    const endpoint = 'integral/shop-create'
    if (param === null) {
      return Vue.http.get(endpoint)
    }
    return Vue.http.post(endpoint, param)
  },
  editShop (id, model = null) {
    if (model === null) {
      return Vue.http.get('integral/shop-edit', { id: id })
    }

    return Vue.http.put('integral/shop-edit', model, { id: id })
  },
  // 积分记录
  integral (param) {
    return Vue.http.get('integral/integral-log', param)
  },
  // 充值记录
  money (param) {
    return Vue.http.get('integral/money-log', param)
  },
  getCountMoney () {
    return Vue.http.get('integral/money-count')
  }
}

export default IntegralService
