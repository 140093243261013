<template>
    <el-form
        v-if="formModel"
        :rules="formRules"
        :model="formModel"
        ref="formModel"
        label-width="140px"
        label-suffix="："
        @submit.native.stop.prevent="handleFormSubmit('formModel')"
    >
        <el-form-item label='类型' prop='type'>
            <el-radio-group v-model="formModel.type">
                <el-radio :label="1" :disabled="isEdit">兑换码</el-radio>
                <el-radio :label="2" :disabled="isEdit">优惠券</el-radio>
                <el-radio :label="3" :disabled="isEdit">商品</el-radio>
            </el-radio-group>
        </el-form-item>

        <el-form-item label='名称' prop='integral_name'>
            <el-col :span="9">
                <el-input
                    v-model="formModel.integral_name"
                    placeholder=""
                ></el-input>
            </el-col>
        </el-form-item>

        <el-form-item label='积分价格' prop='integral_price'>
            <el-col :span="9">
                <el-input
                    type="number"
                    step='0.01'
                    v-model="formModel.integral_price"
                    placeholder=""
                    :min="0.01"
                ></el-input>
            </el-col>
        </el-form-item>

        <el-form-item label='积分横线价格' prop='integral_false_price'>
            <el-col :span="9">
                <el-input
                    type="number"
                    step='0.01'
                    v-model="formModel.integral_false_price"
                    placeholder=""
                    :min="0.01"
                ></el-input>
            </el-col>
        </el-form-item>

        <!-- 兑换码 -->
            <shop-code  v-if="formModel.type === 1"  :formModel="formModel"/>
        <!-- 优惠劵 -->
            <coupon v-if="formModel.type === 2" :formModel="formModel"/>
        <!-- 商品 -->
            <shop  v-if="formModel.type === 3"  :formModel="formModel"/>

        <el-form-item>
            <el-button
                size="medium"
                type="primary"
                native-type="submit"
                :loading="submitLoading"
            >{{ submitText }}</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import ShopCode from './code'
import coupon from './coupon'
import shop from './shop'
export default {
  name: 'ShopForm',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },

  components: {
    ShopCode, coupon, shop
  },

  data () {
    return {
      submitLoading: false,
      formRules: {
        type: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        integral_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        integral_price: [
          { required: true, message: '请输入价格', trigger: 'blur' }
        ],
        integral_false_price: [
          { required: true, message: '请输入价格', trigger: 'blur' }
        ]
      },
      formModel: null
    }
  },

  async mounted () {
    this.formModel = Object.assign(this.model)
  },

  methods: {
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }
        this.submitLoading = true
        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
